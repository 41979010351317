








































import Vue from 'vue';
import { ValidateFields, MutateVuexStore, QSelectFilterFn, QSelectFilterUpdateFn } from '@/assets/mixins';
import { MutationPayload } from '@/interfaces';

export default Vue.extend({
  name: 'Landing-Page',
  data: () => {
    return {
      filteredCities: [''],
      bounceSearch: false,
    };
  },
  computed: {
    area: {
      get () {
        return this.$store.state.search.form.area;
      },
      set (value: string) {
        const payload: MutationPayload = {
          key: 'form.area',
          value,
        };
        this.$store.commit('search/MutateStore', payload);
      },
    },
    possibleCities: {
      get () {
        return this.$store.state.possibleCities as string[];
      },
    },
  },
  mounted () {
    this.filteredCities = this.possibleCities;
    this.area = '';
    // See if search is clicked
    this.bounceSearchArea();
  },
  methods: {
    validateFields (inputs: string[]) {
      return ValidateFields(this, inputs);
    },
    invokeSearch (e: string, done: () => void) {
      // If done, invoke it
      if (done) {
        done();
      }
      // If we have input greater than 3 characters, perform a filter
      if (e && e.length > 3) {
        QSelectFilterUpdateFn(e, this, this.possibleCities, 'filteredCities', 'area');
      }
      // Validate
      this.$nextTick(() => {
        if (this.validateFields(['area'])) {
          this.$router.push('/search/' + this.area);
        }
      });
    },
    filterFn (val: string, update: (func: () => void) => void, abort: () => void) {
      QSelectFilterFn(val, update, abort, this, this.possibleCities, 'filteredCities', 'area');
    },
    bounceSearchArea () {
      const domElement: HTMLElement | null = document.getElementById('headerSearchProperty');
      if (domElement) {
        domElement.addEventListener('click', () => {
          this.bounceSearch = true;
          window.setTimeout(() => {
            this.bounceSearch = false;
          }, 1000);
        });
      }
    },
  },
});
